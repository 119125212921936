import "../styles/globals.scss";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import type { AppProps } from "next/app";
import React from "react";
import HeadSeo from "../src/components/HeadSeo";
import siteMetadata from "../src/lib/data/siteMetadata";
import {
  HydrationBoundary,
  MutationCache,
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { notify } from "../src/utils/notify";
import Script from "next/script";
// import {
//   darkTheme,
//   getDefaultConfig,
//   RainbowKitProvider
// } from "@rainbow-me/rainbowkit";
// import { WagmiProvider } from "wagmi";
// import { mainnet, polygon, optimism, arbitrum, base } from "wagmi/chains";

function BloccpayApp({ Component, pageProps }: AppProps) {
  const mutationCache = new MutationCache({
    onSuccess: (mutation: any) => {
      if (mutation?.message) {
        notify({
          type: "success",
          message: mutation?.message
        });
      }
    },
    onError: (error: any, mutation: any) => {
      if (error?.response?.data?.message) {
        notify({
          type: "error",
          message: error?.response?.data?.message
        });
      }
    }
  });

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 300000
          }
        },
        mutationCache
      })
  );

  // const config = getDefaultConfig({
  //   appName: "Bloccpay Web App",
  //   projectId: "f9a303d69baf7074adca3a52508bb5fe",
  //   chains: [mainnet, polygon, optimism, arbitrum, base],
  //   ssr: true
  // });

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id='${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}'`}
      />

      <Script strategy="lazyOnload" id="google-analytics">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
      </Script>

      <HeadSeo
        title={`${siteMetadata.companyName} `}
        description={siteMetadata.description}
        canonicalUrl={`${siteMetadata.siteUrl}`}
        ogImageUrl={siteMetadata.siteLogo}
        ogTwitterImage={siteMetadata.siteLogo}
        ogType={"website"}
      >
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
      </HeadSeo>
      {/* <WagmiProvider config={config}> */}
      <QueryClientProvider client={queryClient}>
        {/* <RainbowKitProvider
            theme={darkTheme({
              accentColor: "#7b3fe4",
              accentColorForeground: "white",
              borderRadius: "small",
              fontStack: "system",
              overlayBlur: "small"
            })}
          > */}
        <HydrationBoundary state={pageProps.dehydratedState}>
          <Component {...pageProps} />
        </HydrationBoundary>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        {/* </RainbowKitProvider> */}
      </QueryClientProvider>
      {/* </WagmiProvider> */}
      <NotificationContainer />
    </>
  );
}

export default BloccpayApp;
